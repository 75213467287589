/* eslint-disable */
/**
 * Created by DIREKTOR on 13.02.2017.
 *
 * заменить
 * urlGo на url
 * jsHideModal на onHide
 * aa_confirm_msg iu.dlg.confirm_close
 * aa_confirm_hide_modal удалить
 * aa_popup_stop iu.popup.stop
 * aa_popup_start iu.popup.start
 * aa_popup_hide iu.popup.hide
 * aa_popup_show iu.popup iu.popup.error
 * aa_dlg_hide_modal iu.dlg.hide
 * aa_dlg iu.dlg.show
 * aa_dlg_youtube iu.dlg.youtube
 * aa_dlg_show iu.dlg aa_dlg_show\(\s*(e|event|null)\s*,\s*1[^0-9]
 * srv_is_scroll iu.is_scroll
 * aa_confirm_show iu.dlg.confirm
 * aa_confirm_yes iu.dlg.confirm_yes
 * aa_form_send iu.ajax
 *
 * setFlash
 * echo json_encode
 * echoErrorPopup echoError
 * Yii::app()->user; $webUser=Yii::app()->user; $wUser=Yii::app()->user;
 * isAjaxRequest проверить за Димой
 * проверить ::popup(,)
 * echo json_encode(['popup'
 * Yii::app()->end();
 * 'reload' => true 'reload' => 1 'reload' => '1'
 *
 */

function Core() {
    const args = Array.prototype.slice.call(arguments, 1);
    const cmd = arguments[0];
    if (!(cmd in Core)) {
        console.log(`No such method "${cmd}" for Core`);
        return;
    }
    Core[cmd].apply(Core, args);
}

Core.user_id = 0;
Core.user_type = 0;
Core.url_socket = '';
Core.is_tested = false;

Core.set = function () {
    if (arguments.length == 1 && typeof arguments[0] === 'object') {
        for (const key in arguments[0]) {
            if (!(key in Core)) {
                console.log(`No such method "${key}" for Core`);
                continue;
            }
            Core[key] = arguments[0][key];
        }
    } else if (arguments.length == 2) {
        if (!(arguments[0] in Core)) {
            console.log(`No such method "${arguments[0]}" for Core`);
        } else {
            Core[arguments[0]] = arguments[1];
        }
    } else console.log('Argument Error');
};

window.setButtonLoading = (e) => {
    if (e !== null && typeof e !== 'undefined') {
        if (jQuery(e.target).attr('disabled')) {
            return false;
        }
        jQuery(e.target).prop('disabled', true).addClass('a-load-button');
    }
    return true;
};

window.removeButtonLoading = (e) => {
    jQuery(e.target).prop('disabled', false).removeClass('a-load-button');
};

function validateFormData(form) {
    if (typeof form === 'string' || typeof form === 'object') {
        if (typeof form === 'string' && form.charAt(0) == '#') {
            jQuery(`${form} .errorSummary`).slideUp(300);
            return {
                objData: jQuery(form).serialize(),
                isForm: true,
            };
        }
        return {
            objData: form,
        };
    }
    return {
        objData: null,
    };
}

Core.ajax = function (e, url, form, callback, options, target) {
    let isShowLoading = true;

    let contentType = 'application/x-www-form-urlencoded; charset=UTF-8';

    if (options !== undefined && options.json !== undefined) {
        contentType = 'application/json';
        form = JSON.stringify(form);
    }

    if (options !== undefined && options.isShowLoading !== undefined) {
        isShowLoading = options.isShowLoading;
    }

    if (isShowLoading && !window.setButtonLoading(e)) {
        return;
    }

    const validate = validateFormData(form);
    const {objData} = validate;
    const {isForm} = validate;

    // eslint-disable-next-line no-param-reassign
    url += `${url.split('?')[1] ? '&' : '?'}isAjax=1`;
    return jQuery.ajax({
        url,
        timeout: 60000,
        tryCount: 0,
        retryLimit: 2,
        xhrFields: {
            withCredentials: true,
        },
        type: 'POST',
        data: objData,
        dataType: 'json',
        contentType,
        error(data, textStatus) {
            if (typeof callback === 'function') callback(data);
            if (textStatus === 'timeout') {
                iu.popup.error('Превышено время ожидания');
                this.tryCount += 1;
                if (this.tryCount <= this.retryLimit) {
                    $.ajax(this);
                    return;
                }
                return;
            }

            if (data.status >= 300) {
                try {
                    const str = JSON.parse(data.responseText);
                    if (str.message !== undefined) Popup.error(str.message);
                    else {
                        Popup.error(
                            `${data.status} ${data.statusText}<hr />${data.responseText}`,
                        );
                    }
                } catch (e) {
                    Popup.error(
                        `${data.status} ${data.statusText}<hr />${data.responseText}`,
                    );
                }
            }
            if (e !== null) {
                jQuery(e.target).removeAttr('disabled').removeClass('a-load-button');
            }
        },
        success(data) {
            let removeDisabling = true;
            if (typeof callback === 'function') callback(data);
            if (data.error !== undefined) {
                switch (data.error.type) {
                    case 'popup':
                        Popup.error(data.error.msg);
                        break;
                    case 'form':
                        if (isForm) {
                            const errorSummary = `${form} .errorSummary`;
                            jQuery(errorSummary).remove();
                            jQuery(data.error.msg).hide().prependTo(form);
                            jQuery(errorSummary).slideDown(300);
                        }
                        break;
                }
            } else if (data.popup !== undefined) {
                Popup(data.popup);
            } else if (data.popupStay !== undefined) {
                Popup(data.popupStay, true);
            }
            if (data.modal !== undefined) {
                let width = '644px';
                let marginTop = '30px';
                if (data.modal.width !== undefined) {
                    width = data.modal.width;
                }
                if (data.modal.marginTop !== undefined) {
                    marginTop = data.modal.marginTop;
                }
                if (data.modal.html !== undefined) {
                    Dlg.show(data.modal.html, width, marginTop, data.modal.transparent);
                }
                if (data.modal.onHide !== undefined) {
                    Dlg.on_hide = data.modal.onHide;
                }
                if (data.modal.hide_on_bg_click !== undefined) {
                    Dlg.hide_on_bg_click = data.modal.hide_on_bg_click;
                }
            }
            if (data.update !== undefined) {
                jQuery(data.update.obj).html(data.update.val);
            }
            if (data.replace !== undefined) {
                jQuery(data.replace.obj).replaceWith(data.replace.val);
            }
            if (data.append !== undefined) {
                jQuery(data.append.obj).append(data.append.val);
            }
            if (data.hideModal !== undefined && data.hideModal) {
                Dlg.hide();
            }
            if (data.reload !== undefined && data.reload) {
                removeDisabling = false;
                location.reload();
            }
            if (data.url !== undefined) {
                removeDisabling = false;
                if (target === '_blank' && !isSafari) {
                    var isSafari = false;

                    const ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        isSafari = ua.indexOf('chrome') === -1;
                    }

                    if (isSafari) {
                        location.href = data.url;
                    } else {
                        window.open(data.url, '_blank');
                    }
                } else {
                    location.href = data.url;
                }
            }
            if (data.js !== undefined) {
                eval(data.js);
            }
            if (data.pay !== undefined) {
                jQuery('body').append(data.pay);
                jQuery('#aa-payment-form').submit();
            }
            // клиент может перезаписать настройку, если например действие это скачивание файла
            if (data.removeDisabling !== undefined) {
                removeDisabling = data.removeDisabling;
            }
            if (e !== null && typeof e !== 'undefined' && removeDisabling) {
                window.removeButtonLoading(e);
            }
        },
    });
};

Core.download = function (event, url, form) {
    if (!window.setButtonLoading(event)) return;
    const validate = validateFormData(form);
    const {objData} = validate;
    const req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');

    req.onreadystatechange = function () {
        if (req.readyState === 4) {
            if (req.status === 200) {
                const blob = req.response;
                const disposition = this.getResponseHeader('Content-Disposition');
                if (disposition) {
                    const fileName = disposition.match(/filename=('|")(.+?)('|")/)[2];
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = decodeURI(fileName);
                    link.click();
                    Dlg.hide();
                } else {
                    Popup.error('Обновите страницу и попробуйте позже.');
                }
            } else if (req.responseText !== '') {
                Popup.error(req.responseText);
            }
            if (event) jQuery(event.target).removeAttr('disabled').removeClass('a-load-button');
        } else if (req.readyState === 2) {
            if (req.status === 200) {
                req.responseType = 'blob';
            } else {
                req.responseType = 'text';
            }
        }
    };

    req.send(objData);
};

Core.is_scroll = function () {
    if (-[1]) {
        if (document.body.offsetHeight > window.innerHeight) return true;
        return false;
    }
    if (document.body.offsetHeight > document.documentElement.clientHeight) return true;
    return true;
};

Core.global_error_handler = function (msg, url, line) {
    column = 'notset';
    obj = 'notset';
    if (arguments[3] !== undefined) column = arguments[3];
    if (arguments[4] !== undefined) obj = arguments[4];
    Core.ajax(null, '/ajax/jsErrorLog', {
        msg,
        url,
        line,
        column,
        obj,
    });
    return true;
};

/// ////////////////////////////////////////////
/// Popup
/// ////////////////////////////////////////////

function Popup(msg) {
    Popup.msg = msg;
    Popup.b_stay = arguments[1] !== undefined && arguments[1];
    Popup.b_error = false;
    Popup._show.apply(Popup);
}

Popup.msg = '';
Popup.interval = null;
Popup.b_stay = false;
Popup.b_error = false;
Popup.default_errors_stay = false;

Popup.error = function (msg, stay) {
    Popup.msg = msg;
    if (stay === undefined) {
        Popup.b_stay = Popup.default_errors_stay;
    } else {
        Popup.b_stay = stay;
    }
    Popup.b_error = true;
    Popup._show.apply(Popup);
};

Popup.start = function () {
    if (!Popup.b_stay) {
        Popup.interval = setTimeout(() => {
            Popup.hide(1500);
        }, 3000);
    }
};

Popup.stop = function () {
    clearInterval(Popup.interval);
    Popup.interval = null;
};

Popup.hide = function () {
    Popup.stop();
    jQuery('#aa-popup').fadeOut(arguments[0] !== undefined ? arguments[0] : 300);
};

Popup._show = function () {
    Popup.stop();
    jQuery('#aa-popup-msg').html(Popup.msg);
    jQuery('#aa-popup').attr(
        'class',
        Popup.b_error ? 'modal-popup modal-popup--error' : 'modal-popup',
    );
    jQuery('#aa-popup').stop(true, true).fadeIn(600);
    Popup.start();
};

Core.popup = Popup;

/// ////////////////////////////////////////////
/// Dlg
/// ////////////////////////////////////////////

function Dlg() {
    let e = null;
    let action = '';
    const data = {};
    const a = 0;
    const b = 0;
    if (arguments[0] !== undefined) e = arguments[0];
    if (arguments[1] !== undefined) action = `/${arguments[1]}`;
    if (arguments[2] !== undefined) data.a = arguments[2];
    if (arguments[3] !== undefined) data.b = arguments[3];

    // Options
    let options = {};
    if (arguments[4] !== undefined) {
        options = arguments[4];
    }

    Core.ajax(e, `/dlg${action}`, data, null, options);
}

Dlg.scroll_width = 0;
Dlg.callback_close = null;
Dlg.on_hide = null;
Dlg.confirm_close = null;
Dlg.confirm_msg = null;
Dlg.confirm_callback = null;
Dlg.hide_on_bg_click = true;

Dlg.show = function (html, width = '644px', marginTop = '64px') {
    Popup.hide();
    if (width === 0) {
        width = 'fit-content';
    }

    jQuery('body').css('overflow', 'hidden');

    if (Core.is_scroll()) {
        jQuery('body')
            .add('.i-menu')
            .css('padding-right', `${Dlg.scroll_width}px`);
    }

    jQuery('#aa-modal-bg').css('overflow-y', 'auto').css('display', 'flex').css('flex-direction', 'column')
        .show();
    jQuery('#aa-modal-dlg').stop(true, true);
    jQuery('#aa-modal-dlg>*').remove();
    jQuery('#aa-modal-dlg').html(html);
    jQuery('#aa-modal-dlg')
        .css('width', width)
        .css('margin-top', marginTop)
        .fadeIn(300);

    if (arguments[3] !== undefined) {
        jQuery('#aa-modal-dlg').css('background-color', 'transparent');
    } else {
        // when we open modal with argument 3, and then without [reset]
        jQuery('#aa-modal-dlg').css('background-color', '');
    }
};

Dlg.hide = function () {
    if (arguments[0] !== undefined) Dlg.confirm_close = arguments[0];
    if (Dlg.confirm_close) {
        Popup(Dlg.confirm_close, true);
        return;
    }
    if (typeof Dlg.callback_close === 'function') {
        Dlg.callback_close();
        Dlg.callback_close = null;
    }
    if (Dlg.on_hide) {
        eval(Dlg.on_hide);
        Dlg.on_hide = null;
    }
    jQuery('[id|=aa-modal]').fadeOut(300, () => {
        jQuery('body')
            .css('overflow', 'auto')
            .add('.v-menu-bg')
            .css('padding-right', 'inherit');
        // jQuery(".a-top-m").css("margin-top", "100px");
        jQuery('#aa-header').addClass('a-fixd');
        jQuery('#aa-modal-dlg').html('');
    });
};

// todo: доработать диалог подтверждения
Dlg.confirm = function (e, title, msg, callback) {
    Dlg.confirm_callback = callback;
    let src = Dlg.confirm_msg;
    src = src.replace(/\{title\}/gi, title);
    src = src.replace(/\{msg\}/gi, msg);
    Dlg.show(src);
};

Dlg.confirm_yes = function () {
    Dlg.hide();
    if (typeof Dlg.confirm_callback === 'function') Dlg.confirm_callback();
};

Dlg.youtube = function (src, title) {
    Dlg.show(
        `<div class="dg-popup"><a class="dg-popup__close" href="javascript:;" onclick="iu.dlg.hide();"><i class="icon icon-close"></i></a><div class="dg-popup__title">${
            title
        }</div><div style='position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;'><iframe style='position: absolute;top:0;left:0;width:100%;height: 100%;border-width: 0;outline-width: 0;' width="560" height="315" class="dg-course-execute__iframe" src="//www.youtube.com/embed/${
            src
        }?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe></div></div>`,
    );
};

Dlg.bg_click = function () {
    if (Dlg.clickInside) {
        return;
    }
    if (iu.dlg.hide_on_bg_click) {
        iu.dlg.hide();
    }
};

function setupDlgListeners() {
    const dlg = jQuery('#aa-modal-dlg');
    const bg = jQuery('#aa-modal-bg');
    if (dlg.length && bg.length) {
        dlg[0].addEventListener(
            'mousedown',
            (e) => {
                Dlg.clickInside = true;
            },
            true,
        );
        bg[0].addEventListener(
            'mousedown',
            (e) => {
                Dlg.clickInside = false;
            },
            true,
        );
    }
}

$(setupDlgListeners);

Core.dlg = Dlg;

function Tooltip(e, m, options) {
    if (!e) {
        return;
    }
    const {target} = e;
    // iu.tooltip.hide();
    const tooltip = jQuery('<span>')
        .addClass('a-tooltip')
        .css({
            display: 'none',
            top: `${e.pageY - 10}px`,
            left: `${e.pageX + 20}px`,
        })
        .html(m)
        .appendTo('body')
        .fadeIn('slow');
    if (options) {
        const rect = target.getBoundingClientRect();
        const body = document.body.getBoundingClientRect();
        if (options.position === 'top') {
            var top = rect.top - body.top - tooltip.outerHeight() - 20;
        } else if (options.position === 'bottom') {
            top = rect.top + rect.height - body.top + 20;
        }
        const left = rect.left + rect.width / 2 - body.left - tooltip.outerWidth() / 2;
        tooltip.css({
            top,
            left,
        });
    }
}

Tooltip.hide = function (e) {
    jQuery('.a-tooltip').remove();
};

Core.tooltip = Tooltip;

Core.scrollIntoView = function (el) {
    const $el = $(el);
    if ($el.length) {
        const offset = $el.offset().top - 50;
        $('body,html').stop(true).animate({scrollTop: offset});
    }
};

Socket = {};

Socket.init = function () {
    if (!Core.user_id) {
        return;
    }
    Socket.connect = io.connect(Core.url_socket, {
        transports: ['websocket']
    });

    Socket.connect.on('connect', () => {
        const room = `personalRoom${Core.user_id}`;
        Socket.connect.emit('join', {room});
    });

    Socket.connect.on('errors', (data) => {
        console.log(data);
    });

    Socket.connect.on('disconnect', (data) => {
        console.log(data);
    });
};

// =================

Core.sct = Socket;

// ---------------------------------

function init() {
    window.iu = Core;
}

init();

jQuery(() => {
    // ширина вертикального скрола
    const div = document.createElement('div');
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    iu.dlg.scroll_width = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    // отключение всплывания событий
    jQuery('#aa-modal-dlg').click((event) => {
        event.stopPropagation();
    });

    function bottomStickyInfo() {
        $(window).scroll(function () {
            const scrollTop = $(this).scrollTop();
            const hWindow = $(this).height();
            const hDocument = $(document).height();
            const hMobileMenu = 57;
            const hPage = scrollTop + hWindow + hMobileMenu;
            if (hDocument <= hPage) {
                $('.bottom-sticky-info').fadeOut();
            } else {
                $('.bottom-sticky-info').fadeIn();
            }
        });
    }

    $(window).on('load resize', () => {
        const w = $(window).width();
        if (w < 991) {
            bottomStickyInfo();
        }
    });

    // скрытие и отображение меню пользователя
    const touch = $('#touch-menu');
    const menu = $('.kr-navbar');
    $(touch).on('click', (e) => {
        e.preventDefault();
        menu.slideToggle(300);
    });
    $(window).resize(() => {
        const w = $(window).width();
        if (w > 767 && menu.is(':hidden')) {
            menu.removeAttr('style');
        }
    });

    $('.js-scroll-link').on('click', function (event) {
        // отменяем стандартную обработку нажатия по ссылке
        event.preventDefault();
        // забираем идентификатор бока с атрибута href
        const id = $(this).attr('href');
        const target = $(id);
        const offset = target.offset();
        if (!offset) return;
        // узнаем высоту от начала страницы до блока на который ссылается якорь
        let landTop = offset.top;
        landTop = +landTop - 41;
        // анимируем переход на расстояние - top за 400 мс
        $('body,html').stop(true).animate({scrollTop: landTop});
    });
});

/* eslint-disable @typescript-eslint/no-use-before-define */
// Рейтинг сайта
jQuery(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function initSiteRatingButton() {
        const button = document.createElement('button');
        const span = document.createElement('span');

        button.setAttribute('type', 'button');
        button.classList.add('site-rating-button');

        span.textContent = 'Оцените сайт';

        button.appendChild(span);
        document.body.appendChild(button);

        initSiteRating();
    }

    function initSiteRating() {
        const questionMap = {
            1: 'Вас что-то огорчило? Пожалуйста, расскажите об&nbsp;этом, чтобы мы&nbsp;смогли это исправить.',
            2: 'Вас что-то огорчило? Пожалуйста, расскажите об&nbsp;этом, чтобы мы&nbsp;смогли это исправить.',
            3: 'Спасибо!<br/>Посоветуйте, что мы должны сделать лучше?',
            4: 'Спасибо!<br/>Посоветуйте, как мы могли бы сделать его ещё лучше?',
            5: 'Спасибо!<br/>Что нравится больше всего?',
        };

        const modal = document.querySelector('#aa-modal-dlg');
        const modalOverlay = modal.parentNode;
        const ratingButton = document.querySelector('.site-rating-button');

        if (ratingButton) {
            ratingButton.addEventListener('click', () => {
                getTemplate('usability')
                    .then((result) => {
                        if (result.data.template) {
                            iu.dlg.show(result.data.template, '552px', 'auto');

                            initRating();
                        }
                    });
            });
        }

        function initRating() {
            initStarsClickHandlers();

            const formData = {};
            const modalCloseButton = document.querySelector('.modal-close');

            modalOverlay.addEventListener('click', handleCloseUsabilityModal);
            modalCloseButton.addEventListener('click', () => {
                handleCloseUsabilityModal();
                iu.dlg.hide();
            });

            const form = document.querySelector('.site-rating__form');
            const formButton = form.querySelector('.button');

            form.addEventListener('submit', (event) => {
                event.preventDefault();

                const formProps = getFormData(event.target);

                formData.rank = Number(formProps.rank);
                formData.review = formProps.review;

                if (isValidReview(formData.review)) {
                    setLoading(formButton, true);

                    getTemplate('suggestion')
                        .then((result) => {
                            if (result?.data?.template) {
                                modal.innerHTML = result.data.template;
                                modalOverlay.removeEventListener('click', handleCloseUsabilityModal);

                                initLastStep(formData);
                            }
                        })
                        .catch(() => {
                            setLoading(formButton, false);
                        });
                }
            });

            function handleCloseUsabilityModal() {
                const formProps = getFormData(form);

                if (formProps.rank && isValidReview(formProps.review)) {
                    fetchReview({
                        ...formProps,
                        rank: Number(formProps.rank),
                        suggestion: '',
                    });
                }

                modalOverlay.removeEventListener('click', handleCloseUsabilityModal);
                removeModalContent();
            }
        }

        function initStarsClickHandlers() {
            const starRadios = document.querySelectorAll('.site-rating__radio');
            const questionNode = document.querySelector('.site-rating__review-title');

            starRadios.forEach((starRadio) => {
                starRadio.addEventListener('change', (event) => {
                    const {value} = event.target;

                    questionNode.innerHTML = questionMap[value];

                    showForm();
                });
            });
        }

        function initLastStep(prevFormData) {
            const modalCloseButton = document.querySelector('.modal-close');

            modalOverlay.addEventListener('click', handleCloseSuggestionModal);
            modalCloseButton.addEventListener('click', () => {
                handleCloseSuggestionModal();
                iu.dlg.hide();
            });

            const form = document.querySelector('.site-rating__form-completion');
            const formButton = form.querySelector('.button');

            form.addEventListener('submit', (event) => {
                event.preventDefault();

                const formProps = getFormData(event.target);
                const data = {
                    ...prevFormData,
                    ...formProps,
                };

                if (isValidReview(prevFormData.review) && isValidReview(formProps.suggestion)) {
                    setLoading(formButton, true);

                    fetchReview(data, (result) => {
                        if (!result.data?.errors?.length) {
                            iu.dlg.hide();
                            modalOverlay.removeEventListener('click', handleCloseSuggestionModal);
                        }
                    });
                }
            });

            function handleCloseSuggestionModal() {
                const formProps = getFormData(form);

                if (isValidReview(prevFormData.review) && isValidReview(formProps.suggestion)) {
                    fetchReview({
                        ...prevFormData,
                        ...formProps,
                    });
                }

                modalOverlay.removeEventListener('click', handleCloseSuggestionModal);
                removeModalContent();
            }
        }

        function showForm() {
            const fields = document.querySelectorAll('.site-rating__field');

            fields.forEach((field) => {
                field.classList.add('site-rating__field--visible');
            });
        }

        function getFormData(form) {
            const formData = new FormData(form);

            return Object.fromEntries(formData);
        }

        function setLoading(targetButton, loading) {
            // eslint-disable-next-line no-param-reassign
            targetButton.disabled = loading;

            if (loading) {
                targetButton.classList.add('a-load-button');
            } else {
                targetButton.classList.remove('a-load-button');
            }
        }

        function removeModalContent() {
            modal.innerHTML = '';
        }

        function fetchReview(data, callback) {
            iu.ajax(null, '/customerReview/api/create', data, (response) => {
                if (callback) {
                    callback(response);
                }
            });
        }

        function getTemplate(code) {
            if (code === 'usability') {
                return fetch('/customerReview/api/getModalTemplate?code=usability')
                    .then((response) => response.json());
            }

            if (code === 'suggestion') {
                return fetch('/customerReview/api/getModalTemplate?code=suggestion')
                    .then((response) => response.json());
            }
        }

        function isValidReview(str) {
            const MAX_REVIEW_SYMBOLS_LENGTH = 800;
            return str.length <= MAX_REVIEW_SYMBOLS_LENGTH;
        }
    }
});
